@import url(https://fonts.googleapis.com/css?family=Open+Sans%3A400%2C700&display=swap&ver=3.0.4);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css);
html {
  height: -webkit-fill-available;
}

body,
html {
  margin: 0;
  height: 100%;
}

body {
  color: #2e2e2e !important;
  font-size: 1em !important;
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[readonly] {
  cursor: not-allowed !important;
}

ul {
  list-style: none;
}

label {
  font-size: 18px;
  font-weight: 600 !important;
  line-height: 20px;
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1170px !important;
  }
}

.form-control {
  text-align: left !important;
  height: 45px;
  color: #002db3 !important;
  /* background: #f7f7f7 !important; */
  border: 1px solid #c4c4c4 !important;
  border-radius: 0 !important;
  line-height: 2 !important;
}

.btn-primary {
  background-color: #002db3 !important;
  border-color: #002db3 !important;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 18px !important;
  font-weight: 200 !important;
  position: relative;
}

.navbar-light .navbar-nav .nav-link.fb-menu-item:hover {
  color: #2f5aee !important;
}

.navbar-light .navbar-nav .nav-link.fb-menu-item:hover::after {
  width: 100%;
}

.navbar-light .navbar-nav .nav-link.fb-menu-item::after {
  position: absolute;
  content: '';
  display: block;
  margin: 0 auto;
  width: 0;
  height: 2px;
  transition: opacity 0.3s ease, width 0.3s ease;
  right: 0;
  left: 0;
  pointer-events: none;
  background-color: #2f5aee;
}

body .text-primary {
  color: #002db3 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-input-placeholder {
  /* Edge */
  text-align: left;
}

::placeholder {
  text-align: left;
}

.fb-error-message {
  font-size: 21px;
  color: #dd3333;
}

.fb-footer .top-footer {
  background: #f2f2f2;
  padding: 8px 0;
}

.fb-footer .top-footer h6 {
  line-height: 1.6em;
  margin-bottom: 30px;
}

.bottom-footer {
  background: #e5e5e5;
  padding: 8px 0;
  color: #8d8b8b;
  font-size: 15px;
  line-height: 16px;
}

/* Loader css */
.progress {
  background-color: rgb(229, 233, 235);
  height: 0.2em;
  position: relative;
  width: 25%;
  margin: 0 auto;
}

/* footer{
  position: fixed;
  bottom: 0;
  width: 100%;
} */

.progress-bar-fb {
  background-size: 23em 0.25em;
  height: 100%;
  position: relative;
  background-color: var(--bg);
  /* #e04542;*/
  animation: cssload-width 1.2s cubic-bezier(0.45, 0, 1, 1) infinite;
  -o-animation: cssload-width 1.2s cubic-bezier(0.45, 0, 1, 1) infinite;
  -ms-animation: cssload-width 1.2s cubic-bezier(0.45, 0, 1, 1) infinite;
  -webkit-animation: cssload-width 1.2s cubic-bezier(0.45, 0, 1, 1) infinite;
  -moz-animation: cssload-width 1.2s cubic-bezier(0.45, 0, 1, 1) infinite;
}

th {
  vertical-align: middle;
  font-size: 14px;
}

.search-wrapper {
  float: right;
  margin-bottom: 15px;
  position: relative;
}

.filter-wrapper {
  float: left;
}

.search-wrapper i,
.search-wrapper button {
  position: absolute;
  right: 10px;
  line-height: 45px;
}

.search-wrapper button {
  border: none;
  background: none;
}

.search-wrapper input {
  padding-right: 35px;
}

th i {
  float: right;
  cursor: pointer;
}

@keyframes cssload-width {

  0%,
  100% {
    transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }

  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@-webkit-keyframes cssload-width {

  0%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }

  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}


table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

th {
  border-bottom: 1px solid #ddd !important;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
section{
    flex: 1 0 auto;
    margin: 1.6em;
    background-color: #fff;
    padding: 1.5em;
}
section.container{
    min-height: calc(100vh - 340px);
}
table.lookup-table th {
    white-space: nowrap;
    padding-right: 30px;
    position: relative;
}

table.lookup-table td {
    white-space: nowrap;
}

table.lookup-table th i {
    position: absolute;
    right: 5px;
}
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 28px;
    float: none;
}

/* Hide default HTML checkbox */
.switch input {
    display: none;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input.default:checked+.slider {
    background-color: #444;
}

input.primary:checked+.slider {
    background-color: #2196F3;
}

input.success:checked+.slider {
    background-color: #8bc34a;
}

input.info:checked+.slider {
    background-color: #3de0f5;
}

input.warning:checked+.slider {
    background-color: #FFC107;
}

input.danger:checked+.slider {
    background-color: #f44336;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.search-wrapper {
    float: right;
    margin-bottom: 15px;
    position: relative;
}

.filter-wrapper {
    float: left;
}

.search-wrapper i {
    position: absolute;
    right: 10px;
    line-height: 45px;
}

.search-wrapper input {
    padding-right: 35px;
}

th i {
    float: right;
    cursor: pointer;
}
.pagination-container {
    display: flex;
    list-style-type: none;
}

.pagination-container .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
}

.pagination-container .pagination-item.dots:hover {
    background-color: transparent;
    cursor: default;
}

.pagination-container .pagination-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
}

.pagination-container .pagination-item.selected {
    background-color: rgba(0, 0, 0, 0.08);
}

.pagination-container .pagination-item .arrow::before {
    position: relative;
    /* top: 3pt;
    Uncomment this to lower the icons as requested in comments*/
    content: '';
    /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.pagination-container .pagination-item .arrow.left {
    -webkit-transform: rotate(-135deg) translate(-50%);
            transform: rotate(-135deg) translate(-50%);
}

.pagination-container .pagination-item .arrow.right {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.pagination-container .pagination-item.disabled {
    pointer-events: none;
}

.pagination-container .pagination-item.disabled .arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.pagination-container .pagination-item.disabled:hover {
    background-color: transparent;
    cursor: default;
}

.switch.btn {
    min-width: 230px !important;
}
.form-container{
    position: relative;
}
.form-success{
    /* position: absolute; */
    background: #fff;
    z-index: 1;
    padding: 50px;
    height: 100%;
    text-align: center;
}
table.output-report td {
    font-size: 13px;
    word-break: break-word;
}

.bf-download-btn {
    position: absolute;
    top: -55px;
    right: 0;
}

.lo-table th, .lo-table td:not(:first-child)  {
    text-align: center;
}

.lo-table th:nth-child(5) {
    background: rgb(255, 184, 0);
}

.lo-table tr td:nth-child(5), .lo-table-td {
    background: rgb(255, 184, 0);
}
.switch.btn {
    min-width: 230px !important;
}
.form-container{
    position: relative;
}
.form-success{
    /* position: absolute; */
    background: #fff;
    z-index: 1;
    padding: 50px;
    height: 100%;
    text-align: center;
}

.form-container{
    position: relative;
}
.form-success{
    /* position: absolute; */
    background: #fff;
    z-index: 1;
    padding: 50px;
    height: 100%;
    text-align: center;
}
.ip-section {
    padding: 2em 0;
}

.login-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 50%;
}

.wrap {
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 10px 34px -15px rgb(0 0 0 / 24%);
}

.login-wrap {
    width: 50%;
}


@media (max-width: 768px) {
    .wrap .login-bg {
        height: 250px;
        width: 100%;
    }

    .wrap .login-wrap {
        width: 100%;
    }
}

.bf-banner {
    background: #FFB800;
    color: #002DB3;
    padding: 8px;
    font-weight: bold;
}

.bf-banner p {
    margin: 0px;
}
.ip-section{
    padding: 2em 0;
}
.login-bg{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 50%;
}
.wrap{
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 10px 34px -15px rgb(0 0 0 / 24%);
}
.login-wrap{
    width: 50%;
}


@media (max-width: 768px){
.wrap .login-bg {
    height: 250px;
    width: 100%;
}
.wrap .login-wrap {
    width: 100%;
}
}
 .error-template {
     padding: 40px 15px;
     text-align: center;
 }

 .error-actions {
     margin-top: 15px;
     margin-bottom: 15px;
 }

 .error-actions .btn {
     margin-right: 10px;
 }

 .message-box h1 {
     color: #252932;
     font-weight: 700;
     line-height: 98px;
 }

 .login-bg {
     background-size: cover;
     background-repeat: no-repeat;
     background-position: center center;
     width: 50%;
 }
