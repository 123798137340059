.search-wrapper {
    float: right;
    margin-bottom: 15px;
    position: relative;
}

.filter-wrapper {
    float: left;
}

.search-wrapper i {
    position: absolute;
    right: 10px;
    line-height: 45px;
}

.search-wrapper input {
    padding-right: 35px;
}

th i {
    float: right;
    cursor: pointer;
}