@import url('https://fonts.googleapis.com/css?family=Open+Sans%3A400%2C700&display=swap&ver=3.0.4');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');

html {
  height: -webkit-fill-available;
}

body,
html {
  margin: 0;
  height: 100%;
}

body {
  color: #2e2e2e !important;
  font-size: 1em !important;
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[readonly] {
  cursor: not-allowed !important;
}

ul {
  list-style: none;
}

label {
  font-size: 18px;
  font-weight: 600 !important;
  line-height: 20px;
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1170px !important;
  }
}

.form-control {
  text-align: left !important;
  height: 45px;
  color: #002db3 !important;
  /* background: #f7f7f7 !important; */
  border: 1px solid #c4c4c4 !important;
  border-radius: 0 !important;
  line-height: 2 !important;
}

.btn-primary {
  background-color: #002db3 !important;
  border-color: #002db3 !important;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 18px !important;
  font-weight: 200 !important;
  position: relative;
}

.navbar-light .navbar-nav .nav-link.fb-menu-item:hover {
  color: #2f5aee !important;
}

.navbar-light .navbar-nav .nav-link.fb-menu-item:hover::after {
  width: 100%;
}

.navbar-light .navbar-nav .nav-link.fb-menu-item::after {
  position: absolute;
  content: '';
  display: block;
  margin: 0 auto;
  width: 0;
  height: 2px;
  transition: opacity 0.3s ease, width 0.3s ease;
  right: 0;
  left: 0;
  pointer-events: none;
  background-color: #2f5aee;
}

body .text-primary {
  color: #002db3 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-input-placeholder {
  /* Edge */
  text-align: left;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  text-align: left;
}

::placeholder {
  text-align: left;
}

.fb-error-message {
  font-size: 21px;
  color: #dd3333;
}

.fb-footer .top-footer {
  background: #f2f2f2;
  padding: 8px 0;
}

.fb-footer .top-footer h6 {
  line-height: 1.6em;
  margin-bottom: 30px;
}

.bottom-footer {
  background: #e5e5e5;
  padding: 8px 0;
  color: #8d8b8b;
  font-size: 15px;
  line-height: 16px;
}

/* Loader css */
.progress {
  background-color: rgb(229, 233, 235);
  height: 0.2em;
  position: relative;
  width: 25%;
  margin: 0 auto;
}

/* footer{
  position: fixed;
  bottom: 0;
  width: 100%;
} */

.progress-bar-fb {
  background-size: 23em 0.25em;
  height: 100%;
  position: relative;
  background-color: var(--bg);
  /* #e04542;*/
  animation: cssload-width 1.2s cubic-bezier(0.45, 0, 1, 1) infinite;
  -o-animation: cssload-width 1.2s cubic-bezier(0.45, 0, 1, 1) infinite;
  -ms-animation: cssload-width 1.2s cubic-bezier(0.45, 0, 1, 1) infinite;
  -webkit-animation: cssload-width 1.2s cubic-bezier(0.45, 0, 1, 1) infinite;
  -moz-animation: cssload-width 1.2s cubic-bezier(0.45, 0, 1, 1) infinite;
}

th {
  vertical-align: middle;
  font-size: 14px;
}

.search-wrapper {
  float: right;
  margin-bottom: 15px;
  position: relative;
}

.filter-wrapper {
  float: left;
}

.search-wrapper i,
.search-wrapper button {
  position: absolute;
  right: 10px;
  line-height: 45px;
}

.search-wrapper button {
  border: none;
  background: none;
}

.search-wrapper input {
  padding-right: 35px;
}

th i {
  float: right;
  cursor: pointer;
}

@keyframes cssload-width {

  0%,
  100% {
    transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }

  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@-o-keyframes cssload-width {

  0%,
  100% {
    -o-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }

  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@-ms-keyframes cssload-width {

  0%,
  100% {
    -ms-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }

  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@-webkit-keyframes cssload-width {

  0%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }

  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@-moz-keyframes cssload-width {

  0%,
  100% {
    -moz-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }

  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}


table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

th {
  border-bottom: 1px solid #ddd !important;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}