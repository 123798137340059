table.lookup-table th {
    white-space: nowrap;
    padding-right: 30px;
    position: relative;
}

table.lookup-table td {
    white-space: nowrap;
}

table.lookup-table th i {
    position: absolute;
    right: 5px;
}