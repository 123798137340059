.ip-section {
    padding: 2em 0;
}

.login-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 50%;
}

.wrap {
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 10px 34px -15px rgb(0 0 0 / 24%);
}

.login-wrap {
    width: 50%;
}


@media (max-width: 768px) {
    .wrap .login-bg {
        height: 250px;
        width: 100%;
    }

    .wrap .login-wrap {
        width: 100%;
    }
}

.bf-banner {
    background: #FFB800;
    color: #002DB3;
    padding: 8px;
    font-weight: bold;
}

.bf-banner p {
    margin: 0px;
}