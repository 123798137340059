 .error-template {
     padding: 40px 15px;
     text-align: center;
 }

 .error-actions {
     margin-top: 15px;
     margin-bottom: 15px;
 }

 .error-actions .btn {
     margin-right: 10px;
 }

 .message-box h1 {
     color: #252932;
     font-weight: 700;
     line-height: 98px;
 }

 .login-bg {
     background-size: cover;
     background-repeat: no-repeat;
     background-position: center center;
     width: 50%;
 }