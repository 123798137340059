table.output-report td {
    font-size: 13px;
    word-break: break-word;
}

.bf-download-btn {
    position: absolute;
    top: -55px;
    right: 0;
}

.lo-table th, .lo-table td:not(:first-child)  {
    text-align: center;
}

.lo-table th:nth-child(5) {
    background: rgb(255, 184, 0);
}

.lo-table tr td:nth-child(5), .lo-table-td {
    background: rgb(255, 184, 0);
}